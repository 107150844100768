import React from "react";
// If you're using Heroicons, for example, you might import them like this
// Adjust imports based on the actual icons you choose to use
import {
  AcademicCapIcon,
  ComputerDesktopIcon,
  GlobeAltIcon,
  LightningBoltIcon,
  OfficeBuildingIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  HeartIcon,
} from "@heroicons/react/outline";

export default function Features() {
  return (
    <div className="bg-white py-12">
      <div className="container mx-auto">
        <div className="text-center mb-12">
        <h2 className="text-5xl font-bold text-center mb-16">Unique Features</h2>
        </div>
        <div className="grid mt-12 grid-cols-2 md:grid-cols-4 gap-12">
          <div className="feature-item p-6 shadow-lg rounded-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="h-24 w-24 mb-4 text-green-500"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5"
              />
            </svg>

            <h6 className="font-semibold text-3xl">Individual Attention</h6>

            <p className="text-xl">
              Qualified staff gives individual attention in special tutorial
              rooms.
            </p>
          </div>
          <div className="feature-item p-6 shadow-lg rounded-lg">
            {/* <ComputerDesktopIcon className="h-6 w-6 mb-4 mx-auto text-green-500"/> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="h-24 w-24 mb-4 text-red-500"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 0 0 2.25-2.25V6.75a2.25 2.25 0 0 0-2.25-2.25H6.75A2.25 2.25 0 0 0 4.5 6.75v10.5a2.25 2.25 0 0 0 2.25 2.25Zm.75-12h9v9h-9v-9Z"
              />
            </svg>

            <h6 className="font-semibold text-3xl">
              Extensive Use of Technology Aids
            </h6>
            <p className="text-xl">
              Modern teaching aids and innovative programs for children.
            </p>
          </div>
          <div className="feature-item p-6 shadow-lg rounded-lg">
            {/* <GlobeAltIcon className="h-6 w-6 mb-4 mx-auto text-blue-500"/> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="h-24 w-24 mb-4 text-blue-500"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
              />
            </svg>

            <h6 className="font-semibold text-3xl">
              Emphasising Cultural Identity
            </h6>
            <p className="text-xl">
              A blend of Eastern and Western cultures, emphasizing Indian
              heritage.
            </p>
          </div>
          <div className="feature-item p-6 shadow-lg rounded-lg">
            {/* <LightningBoltIcon className="h-6 w-6 mb-4 mx-auto text-red-500"/> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="h-24 w-24 mb-4 text-yellow-500"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 0 1-.657.643 48.39 48.39 0 0 1-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 0 1-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 0 0-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 0 1-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 0 0 .657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 0 1-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 0 0 5.427-.63 48.05 48.05 0 0 0 .582-4.717.532.532 0 0 0-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 0 0 .658-.663 48.422 48.422 0 0 0-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 0 1-.61-.58v0Z"
              />
            </svg>

            <h6 className="font-semibold text-3xl">
              Sports & Physical Activities
            </h6>
            <p className="text-xl">
              Regular yoga, gymnastics, and aerobics with a unique fitness
              program.
            </p>
          </div>
          <div className="feature-item p-6 shadow-lg rounded-lg">
            {/* <OfficeBuildingIcon className="h-6 w-6 mb-4 mx-auto text-yellow-500"/> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="h-24 w-24 mb-4 text-violet-500"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819"
              />
            </svg>

            <h6 className="font-semibold text-3xl">Modern Infrastructure</h6>
            <p className="text-xl">
              State-of-the-art furniture in all classrooms, labs, and library.
            </p>
          </div>
          <div className="feature-item p-6 shadow-lg rounded-lg">
            {/* <ShieldCheckIcon className="h-6 w-6 mb-4 mx-auto text-purple-500"/> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="h-24 w-24 mb-4 text-indigo-500"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z"
              />
            </svg>

            <h6 className="font-semibold text-3xl">Hygiene & Cleanliness</h6>
            <p className="text-xl">
              Maintaining the highest standards of cleanliness and hygiene.
            </p>
          </div>
          <div className="feature-item p-6 shadow-lg rounded-lg">
            {/* <UserGroupIcon className="h-6 w-6 mb-4 mx-auto text-pink-500"/> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="h-24 w-24 mb-4 text-cyan-500"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M10.05 4.575a1.575 1.575 0 1 0-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 0 1 3.15 0v1.5m-3.15 0 .075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 0 1 3.15 0V15M6.9 7.575a1.575 1.575 0 1 0-3.15 0v8.175a6.75 6.75 0 0 0 6.75 6.75h2.018a5.25 5.25 0 0 0 3.712-1.538l1.732-1.732a5.25 5.25 0 0 0 1.538-3.712l.003-2.024a.668.668 0 0 1 .198-.471 1.575 1.575 0 1 0-2.228-2.228 3.818 3.818 0 0 0-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0 1 16.35 15m.002 0h-.002"
              />
            </svg>

            <h6 className="font-semibold text-3xl">Medical Facilities</h6>
            <p className="text-xl">
              Fully equipped medical room with an on-call doctor.
            </p>
          </div>
          <div className="feature-item p-6 shadow-lg rounded-lg">
            {/* <HeartIcon className="h-6 w-6 mb-4 mx-auto text-orange-500"/> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="h-24 w-24 mb-4 text-orange-500"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
              />
            </svg>

            <h6 className="font-semibold text-3xl">Security & Safety</h6>
            <p className="text-xl">
              Security guards ensure safe handing over of children to authorized
              persons.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
