import Image from "next/image";
import Link from "next/link";

import img from "../../public/images/testimonial/client-02.png";

import CallToAction from "../Call-To-Action/CallToAction";

import BlogData from "../../data/blog/blog.json";
import CardFive from "../Cards/Card-Five";
import CounterTwo from "../Counters/Counter-Two";
import TeamFour from "../Team/TeamFour";
import Testimonial from "../Testimonials/Testimonial";
import Gallery from "../Gallery/Gallery";
import ServiceCurriculum from "../Services/Service-Curriculum";
import CategoryOne from "../Category/CategoryOne";

const Kindergarten = () => {
  return (
    <>
      <div
        className="slider-area rbt-banner-10 height-750 bg_image bg_image--11"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner text-center">
                <div className="section-title mb--20">
                  <span className="subtitle bg-coral-opacity">
                    Celebrating 2 Years OF ACADEMIC EXCELLENCE
                  </span>
                </div>
                <h1 className="title display-one text-white">
                  “Education is the most powerful weapon which <br />
                  you can use to change the world
                </h1>
                <div className="read-more-btn mt--40">
                 
                    <span className="icon-reverse-wrapper">
                      <span className="btn-text">Apply For Admission</span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                    </span>
               
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Gallery /> */}
    </>
  );
};

export default Kindergarten;
