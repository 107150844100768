import React from "react";

const Partnerships = () => {
  return (
    <div className="bg-white py-24">
      <div className="container mx-auto px-4">
        <h2 className="text-5xl font-bold text-center mb-16">Educational Partnership with Global Leaders</h2>
        
        {/* Grid Wrapper */}
        <div className="grid md:grid-cols-2 gap-16">
          {/* LXL School Cinema Section */}
          <div className="shadow-lg p-6">
            <img src="/images/school/lxi.png"/>
            <h3 className="text-3xl font-semibold mb-2">1- LXL- SCHOOL CINEMA</h3>
            <h4 className="text-lg  mb-2">FILMS FOR LIFE</h4>
            <p className="mb-4">
              School Cinema, an LXL Ideas brand, is an innovative film-based life-skills learning program for students, parents & educators. It uses cinematic storytelling and thought-provoking activities to teach social, emotional, & life skills for students from K-10.
            </p>
            <p className="mb-4">
              A first-of-its-kind program in the world, School Cinema supports Social Emotional Learning, Life Skills, Mental Well-being, and Social Skills, creating future-ready students.
            </p>
            <p className="mb-4">
              What is IFFF-International Kids Film Festival? A global film festival that utilizes visual storytelling to inspire and educate young minds, held in schools across 20+ countries since 2017.
            </p>
          </div>

          {/* CAE Section */}
          <div className="shadow-lg p-6">
          <img src="/images/school/cambridge.jpg"/>

            <h3 className="text-3xl font-semibold mb-2">2- Learning & Assessment through CAE</h3>
            <h4 className="text-lg mb-2">(Cambridge Assessment English- University of Cambridge, Cambridge, United Kingdom)</h4>
            <p className="mb-4">
              Cambridge English Qualifications are in-depth exams that make learning English enjoyable, effective, and rewarding, aimed at improving speaking, writing, reading, and listening skills step by step.
            </p>
            <p className="mb-4">
              Part of the University of Cambridge, Cambridge Assessment English develops and produces a valuable range of qualifications for learners and teachers of English worldwide.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partnerships;
