import Image from "next/image";
import React from "react";

export default function Intro() {
  return (
    <div className="rbt-about-area about-style-1 bg-color-extra2 rbt-section-gap">
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 order-1 order-lg-1">
            <div className="inner">
              <div className="section-title text-start">
                <span className="subtitle bg-primary-opacity">
                  Welcome to official website
                </span>
                <h2 className="title"> Bliss International School</h2>
                <div className="school-description mt-12">
                  <p>
                    The sapling of <strong>Bliss International School</strong>{" "}
                    (Under the aegis of Renu Avadhesh Verma Foundation) was
                    planted in 2022 with a vision to create an institution where
                    education is a delight for the children. It has come a long
                    way since then and set an excellent example of blending of
                    knowledge and skills with a sense of responsibility and
                    humanism. It is a (C.B.S.E. Affiliation-Awaited) English
                    Medium School of Maholi, Sitapur providing value based
                    quality education to develop all creative aspects of
                    Students personality.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-2 order-lg-2">
            <div className="content">
              <img
                alt="Bliss International School Play Ground"
                src="/images/school/sports.webp"
                className="w-full rounded-lg shadow-xl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
