import { Provider } from "react-redux";
import Store from "@/redux/store";
import Context from "@/context/Context";
import PageHead from "../Head";

import MobileMenu from "@/components/Header/MobileMenu";
import Kindergarten from "@/components/04-kindergarten/04-kindergarten";
import Cart from "@/components/Header/Offcanvas/Cart";
import FooterOne from "@/components/Footer/Footer-One";
import BackToTop from "../backToTop";
import HeaderStyleFour from "@/components/Header/HeaderStyle-Four";
import Intro from "@/components/Homepage/Intro";
import Features from "@/components/Homepage/Features";
import Partnerships from "@/components/Homepage/Partnerships";
import Gallery from "@/components/Gallery/Gallery";

const Home = () => {
  return (
    <>
      <PageHead title="Bliss International School" />
      <Provider store={Store}>
        <Context>
          <MobileMenu />
          <HeaderStyleFour headerType="" />
          <Kindergarten />
           {/* <Intro/> */}
           <Intro/>
           <Features/>
           <Partnerships/>
           <Gallery/>
          <BackToTop />
          <FooterOne />
        </Context>
      </Provider>
    </>
  );
};

export default Home;
